import React, { useEffect, useState, useReducer, ReactNode } from 'react'

import { Header } from '../components/organisms/Header'
import { FooterGDS } from '../components/organisms/FooterGDS'
import { useCookies } from 'react-cookie'
import UiContext from '../shared/context/UiContext'
import { UiReducer } from '../shared/reducers/UiReducer'
import { State } from '../shared/interface/uiInterface'

interface IProps {
  children: ReactNode
}
export const LayoutTemplate: React.FC<IProps> = ({ children }: IProps) => {
  const consentCookieName = 'design_system_cookies_policy'
  const [cookies, setCookie] = useCookies([consentCookieName])
  const cookieConsent: string = cookies[consentCookieName] as string
  const [showCookieWidget, setShowCookieWidget] = useState(true)

  const setGtagConsent = (consent: string) => {
    const consentValue = consent ? 'granted' : 'denied'
    gtag('consent', 'update', {
      analytics_storage: consentValue,
      ad_storage: consentValue,
    })
  }

  const acceptCookie = () => {
    setCookie(consentCookieName, 'true', { secure: true })
    setShowCookieWidget(false)
  }
  const declineCookie = () => {
    setCookie(consentCookieName, 'false', { secure: true })
    setShowCookieWidget(false)
  }

  // after render, if the user has provided a cookie preference (stored
  // in the design_system_cookies_policy cookie) then propagate this to
  // the react state variable
  useEffect(() => {
    if (cookieConsent !== undefined) {
      setShowCookieWidget(false)
      setGtagConsent(cookieConsent)
    }
  }, [cookieConsent])

  const defaultUIContext: State = {
    isPopupOpened: false,
    isLoading: false,
    isLoadingSpinner: false,
    isStickyPopupOpened: false,
    viewExtendedChecks: false,
  }
  const [state, dispatch] = useReducer(UiReducer, defaultUIContext)
  return (
    <UiContext.Provider value={{ state, dispatch }}>
      <div>
        <div
          className={`${
            showCookieWidget ? 'show' : 'hide'
          } govuk-cookie-banner `}
          data-nosnippet
          role="region"
          aria-label="Cookies on this site"
        >
          <div className="govuk-cookie-banner__message govuk-width-container">
            <div className="govuk-grid-row">
              <div className="govuk-grid-column-three-quarters">
                <h2 className="govuk-cookie-banner__heading govuk-heading-m">
                  Cookies on this site
                </h2>

                <div className="govuk-cookie-banner__content">
                  <p className="govuk-body-s">
                    We use some essential cookies to make this website work.
                  </p>
                  <p className="govuk-body-s">
                    We’d like to set additional cookies to understand how you
                    use our website so we can improve our services.
                  </p>
                </div>
              </div>
            </div>

            <div className="govuk-button-group">
              <button
                value="accept"
                type="button"
                name="cookies"
                className="govuk-button"
                data-module="govuk-button"
                onClick={acceptCookie}
                data-cy="accept-cookies"
              >
                Accept optional cookies
              </button>
              <button
                value="reject"
                type="button"
                name="cookies"
                className="govuk-button"
                data-module="govuk-button"
                onClick={declineCookie}
                data-cy="reject-cookies"
              >
                Reject optional cookies
              </button>
              <a
                className="govuk-link"
                href="https://www.ncsc.gov.uk/section/about-this-website/cookies"
                target="_blank"
                data-cy="manage-cookies"
                rel="noreferrer"
              >
                Manage Cookies (opens in a new tab)
              </a>
            </div>
          </div>
        </div>

        <Header />
        <main>{children}</main>
        <FooterGDS />
      </div>
    </UiContext.Provider>
  )
}

export default LayoutTemplate
